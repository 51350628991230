<template>
    <div id="ContactUs">

        <div id="Textures">
            <div id="Heading">
                <b>For More Details <br>Contact Us !</b>
            </div>
            
            <div id="hr"></div>

            <div id="Central-Text">
                Have questions or ready to embark on your next project? Reach out to Evergreen today! Our dedicated team is here to provide expert guidance, personalized solutions, and a seamless experience.
            </div>

            <div id="Contacts">
                <div id="Call-Contact">
                    <div class="icon-background">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon-styling" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/></svg>
                    </div>
                    
                    <div class="Text-style">
                        <p><b>Call Us</b></p>
                        <i>+92 300 5505502</i>
                    </div>
                </div>
                <div id="Email-Contact">
                    <div class="icon-background">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon-styling" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg>
                    </div>
                    <div class="Text-style">
                        <p><b>Email</b></p>
                        <i>info@evergreen.com</i>
                    </div>
                </div>
            </div>
        </div>

        <div id="Forms">
            <form action="">
                <!-- Name Section -->
                <div id="Names">
                    <!-- First Name -->
                    <div id="f-name">
                        <div class="Naming-Labels">
                            <b>First Name</b>
                        </div>
                        <input class="Box-styling-Name" type="text">
                    </div>
                    
                    <!-- last Name -->
                    <div id="l-name">
                        <div class="Naming-Labels">
                            <b>Last Name</b> 
                        </div>
                        <input class="Box-styling-Name" type="text">
                    </div>
                    
                    
                </div>
                <div id="Phone">
                    <div class="Naming-Labels">
                       <b>Phone</b>
                    </div>
                    <input class="Box-styling-ph-em" type="text">
                </div>
                <div id="Email">
                    <div class="Naming-Labels">
                       <b>Email</b>
                    </div>
                    <input class="Box-styling-ph-em" type="text">
                </div>
                <div id="Message">
                    <div class="Naming-Labels">
                       <b>Comment or Messages</b>
                    </div>
                    <Textarea class="Box-styling-Message" ></Textarea>
                </div>
                <div id="submit">
                    <input class="Button" type="submit" value="Submit">
                </div>
            </form>
        </div>

    </div>
</template>
<script>
export default {
    name:'ContactUs'
}
</script>
<style scoped>
    *{
        margin: 0%;
        padding: 0%;
        font-family: 'Poppins', sans-serif;
    }
    #ContactUs{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 600px;
    }
    #ContactUs #Textures{
        margin-left: 20%;
        margin-right: 90px;
    }
    #ContactUs #Textures #Heading{
        font-size: 40px;
        line-height: 45px;
    }
    #ContactUs #Textures #hr{
        border: 3px solid #00A54D;
        width: 12%;
        margin: 10px 0px;
    }
    #ContactUs #Textures #Central-Text{
        color: #5A5A5A;
        font-size: 15px;
        margin: 20px 0px;
    }
    #ContactUs #Textures #Contacts #Call-Contact, #ContactUs #Textures #Contacts #Email-Contact{
        display: flex;
        /* align-items: center; */
        color: #5A5A5A;
        fill: #00A54D;
    }
    #ContactUs #Textures #Contacts #Call-Contact i:hover, #ContactUs #Textures #Contacts #Email-Contact i:hover{
        color: #00A54D;
        text-decoration: underline;
        cursor: pointer;
    }
    #ContactUs #Contacts{
        margin: 30px 0px;
        display: flex;
    }
    #Call-Contact{
        margin-right: 50px;
    }
    .icon-background{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        background-color:#8efdc2;
        border-radius: 25px;
        padding: 5px 5px;
    }
    .icon-styling{
        padding: 0px 10px;
    }
    
    .Text-style{
        margin-left: 10px;
        margin-right: 20px;
        font-size: 16px;
    }
    .Text-style i{
        font-size: 13px;
        text-align: center;
    }
    #ContactUs #Forms{
        margin-right: 20%;
    }
    #fname{
        margin-right: 2%;
    }
    #l-name{
        margin-left: 2%;
    }
    #ContactUs #Forms #Names{
        display: flex;
    }
    .Naming-Labels{
        font-size: 13px;
        margin-left: 10px;
        margin-bottom: 5px;
    }
    .Box-styling-Name{
        padding: 0px 20px;
        margin: 5px 0px;
        margin-left: 10px;
        height: 40px;
        width: 186px;
    }
    .Box-styling-ph-em{
        padding: 0px 20px;
        margin: 5px 0px;
        margin-left: 10px;
        height: 40px;
        width: 435px;
    }
    .Box-styling-Message{
        padding: 10px 20px;
        margin: 5px 0px;
        margin-left: 10px;
        height: 150px;
        width: 435px;
    }
    .Button{
        background-color: #00A54D;
        margin-left: 10px;
        border: none;
        color: white;
        padding: 10px 220px;
        text-align: center;
    }
    .Button:hover{
        border: 3px solid #00A54D;
        color: #00A54D;
        background-color: white;
        cursor: pointer;
    }
    #Names, #Phone, #Email, #Message, #submit{
        margin-top: 15px;
    }

    /* Responsive */

    /* Responsive on pixels 1440 */
    @media screen and (max-width: 1440px) {
     /* No need for any change */
    }

    /* Responsive on pixels 1024 */
    @media screen and (max-width:1024px){
        #ContactUs #Textures{
        /* margin-left: 20%; */
        margin-right: 2%;
    }
    #ContactUs #Textures #Heading{
        font-size: 32px;
        /* line-height: 45px; */
    }
    #ContactUs #Textures #Central-Text{
        /* color: #5A5A5A; */
        font-size: 13px;
        /* margin: 20px 0px; */
    }
    .icon-background{
        /* display: flex;
        align-items: center;
        justify-content: center; */
        height: 35px;
        width: 35px;
        background-color:#8efdc2;
        border-radius: 25px;
        padding: 5px 5px;
    }
    .icon-styling{
        padding: 0px 10px;
    }
    .Text-style{
        margin-left: 10px;
        margin-right: 20px;
        font-size: 13px;
    }
    .Text-style i{
        font-size: 11px;
        text-align: center;
    }
    .Box-styling-Name{
        /* padding: 0px 20px;
        margin: 5px 0px;
        margin-left: 10px; */
        /* height: 40px; */
        width: 126px;
    }
    .Box-styling-ph-em{
        /* padding: 0px 20px;
        margin: 5px 0px;
        margin-left: 10px;
        height: 40px; */
        width: 312px;
    }
    .Box-styling-Message{
        /* padding: 10px 20px;
        margin: 5px 0px;
        margin-left: 10px;
        height: 150px; */
        width: 312px;
    }
    .Button{
        background-color: #00A54D;
        margin-left: 10px;
        border: none;
        color: white;
        padding: 10px 158px;
        text-align: center;
    }
    }

    /* Responsive on pixels 768 */
    @media screen and (max-width:768px){
        #ContactUs{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 950px;
    }
    #ContactUs #Textures{
        margin-left: 0%;
        margin-right: 0%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    #ContactUs #Textures #Heading{
        text-align: center;
    }
    #ContactUs #Textures #Central-Text{
        text-align: center;
        margin: 20px 10%;
    }
    #ContactUs #Forms{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 0%;
    }
    #Call-Contact{
        margin-right: 0px;
    }
    }

    /* Responsive on pixels 425 */
    @media screen and (max-width:425px){
        .icon-background{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 30px;
            width: 30px;
            background-color:#8efdc2;
            border-radius: 25px;
            padding: 2.5px 2.5px;
        }
        #ContactUs {
            height: 1050px;
        }
    }

    /* Responsive on pixels 375 */
    @media screen and (max-width:375px){ 
        #ContactUs #Textures #Contacts{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .Naming-Labels{
            margin-left: 17px;
    }
        .Box-styling-Name{
        /* padding: 0px 20px;
        margin: 5px 0px; */
        margin-left: 17px;
        /* height: 40px; */
        width: 90px;
    }
    .Box-styling-ph-em{
        /* padding: 0px 20px;
        margin: 5px 0px;
        margin-left: 10px;
        height: 40px; */
        margin-left: 17px;
        width: 250px;
    }
    .Box-styling-Message{
        /* padding: 10px 20px;
        margin: 5px 0px;
        margin-left: 10px;
        height: 150px; */
        margin-left: 17px;
        width: 250px;
    }
    .Button{
        background-color: #00A54D;
        margin-left: 17px;
        border: none;
        color: white;
        padding: 10px 127px;
        text-align: center;
    }
    }
    /* Responsive on pixels 320 */
</style>