<template lang="">
    <div>
        <navigationBar pageName="Contact Us"/>
        <ContactUs/>
        <FollowUs/>
        <Footers />
    </div>
</template>
<script>
import navigationBar from '../components/navigationBar.vue'
import ContactUs from '../components/ContactUs.vue';
import FollowUs from '../components/FollowUs.vue';
import Footers from '../components/AppFooter.vue';
export default {
    name: "ContactPage",
    components:{
        navigationBar,
        ContactUs,
        FollowUs,
        Footers
    }
}
</script>
<style lang="">
    
</style>