<template lang="">
    <div id="Slider">
        <div class="mysliders fade" id="slide1">
            <div id="img"><div id="color"></div></div>
            <div id="content">
                <p class="heading">We are Best Constructors</p>
                <p>Welcome to EverGreen Corporation, where our commitment to excellence in construction is unmatched. With years of industry experience and a dedicated team of professionals, we take pride in delivering exceptional results that exceed expectations. From residential to commercial projects, we approach each endeavor with passion and precision, ensuring that every detail is meticulously crafted to perfection.</p>
                <button onclick="document.location='#/services'">More Details</button>
            </div>
        </div>
        <div class="mysliders fade" id="slide2">
            <div id="img"><div id="color"></div></div>
            <div id="content">
                <p class="heading">We Build Your Dreams</p>
                <p>With a relentless dedication to excellence and a passion for precision, we bring your visions to life with unparalleled expertise and craftsmanship. Our team of skilled professionals works tirelessly to ensure that every detail is executed flawlessly, from the initial concept to the final touches. Partner with us, and experience the transformation of your dreams into tangible structures that stand as testaments to our commitment to quality and innovation."</p>
                <button onclick="document.location='#/services'">More Details</button>
            </div>
        </div>
        <div id="indicators">
            <div class="dot" @click="currentSlide(1)"></div> 
            <div class="dot" @click="currentSlide(2)"></div> 
        </div>
        <a class="prev" @click="plusSlides(-1)">❮</a>
        <a class="next" @click="plusSlides(1)">❯</a>
    </div>
</template>
<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Slider",
}
</script>

<script setup>
import { onMounted } from 'vue'
let slideIndex = 1;

onMounted(() => {
    showSlides(slideIndex);
})

    function plusSlides(n) {
        showSlides(slideIndex += n);
    }

    function currentSlide(n) {
        showSlides(slideIndex = n);
    }

    function showSlides(n) {
        let i;
        let slides = document.getElementsByClassName("mysliders");
        let dots = document.getElementsByClassName("dot");
        if (n > slides.length) {slideIndex = 1}    
        if (n < 1) {slideIndex = slides.length}
        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";  
        }
        for (i = 0; i < dots.length; i++) {
            dots[i].className = dots[i].className.replace(" active", "");
        }
        slides[slideIndex-1].style.display = "block";  
        dots[slideIndex-1].className += " active";
    }
</script>
<style>
    *{
        margin: 0;
        padding: 0;
        font-family: 'Poppins', sans-serif;
        box-sizing: border-box;
    }

    #Slider {
        position: relative;
    }
    
    #Slider .mysliders {
        display: none;
        height: 800px;
        width: 100%;
        overflow: hidden;
        position: relative;
    }

    #Slider #slide1 #img {
        height: 100%;
        width: 100%;
        background-image: url("../assets/slide1.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transform: scale(1.05);
        animation: crescendo 3s ease-in;
        position: relative;
    }
    
    #Slider #slide2 #img {
        position: relative;
        height: 100%;
        width: 100%;
        background-image: url("../assets/slide2.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transform: scale(1.05);
        animation: crescendo 3s ease-in;
    }

    @keyframes crescendo {
        0%   {
            -ms-transform: scale(1);
            -moz-transform: scale(1);
            -webkit-transform: scale(1);
            -o-transform: scale(1);
            transform: scale(1);
        }
        100% {
            -ms-transform: scale(1.05);
            -moz-transform: scale(1.05);
            -webkit-transform: scale(1.05);
            -o-transform: scale(1.05);
            transform: scale(1.05);
        }
    }
    
    #Slider .mysliders #color {
        height: 100%;
        width: 100%;
        background-color: rgb(0, 0, 0, 0.3);

    }

    #Slider a {
        font-size: 35px;
        height: 60px;
        width: 60px;
        color: #fff;
        position: absolute;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.6s ease;
        cursor: pointer;
        top: 50%;
    }
    
    #Slider a:hover {
        background-color: #00A54D;
    }
    
    #Slider .prev {
        left: 0;
        transform: translate(0%, -50%);
    }
    
    #Slider .next {
        transform: translate(0%, -50%);
        right: 0;
    }

    #Slider #indicators {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        width: 100%;
        position: absolute;
        margin-top: -40px;
    }

    #Slider #indicators .dot {
        width: 25px;
        height: 8px;
        background-color: #fff;
        border-radius: 5px;
        /* z-index: 1; */
        margin: 0px 3px;
        transition: background-color 0.6s ease;
    }

    #Slider #indicators .active, #Slider #indicators .dot:hover {
        width: 40px;
        height: 8px;
        background-color: #00A54D;
        border-radius: 5px;
    }

    #Slider #content {
        color: #f2f2f2;
        font-size: 15px;
        padding: 8px 12px;
        position: absolute;
        left: 10.416667%; /* 200px/1920 */
        right: 200px;
        width: 75%;
        /* text-align: center; */
        top: 50%;
        transform: translate(0%, -50%);
        overflow: hidden;
    }

    #Slider #content p:first-child {
        font-size: 63px;
        font-weight: 600;
    }

    #Slider #content p:nth-child(2) {
        font-size: 18px;
        font-weight: 400;
        margin-top: 20px;
        width: 50%;
    }
    #Slider #content button #btnD {
        color: #fff;
        text-decoration: none;
        font-size: 14px;
    }

    #Slider #content button {
        cursor: pointer;
        width: 180px;
        height: 60px;
        background-color: #00A54D;
        color: #fff;
        margin-top: 50px;
        font-size: 14px;
        border: none;
    }

    #Slider #content button:hover {
        background-color: white;
        color: black;
    }

    @media screen and (max-width: 1024px) {
        #Slider .mysliders {
            height: 650px;
        }

        #Slider #content p:first-child {
            font-size: 50px;
        }

        #Slider #content p:nth-child(2) {
            width: 60%;
        }
    }
    
    @media screen and (max-width: 770px) {
        #Slider .mysliders {
            height: 500px;
        }

        #Slider #content p:first-child {
            font-size: 30px;
        }

        #Slider #content p:nth-child(2) {
            width: 60%;
            font-size: 14px;
        }
    }

    @media screen and (max-width: 600px) {
        #Slider .mysliders {
            height: 400px;
        }

        #Slider #content p:first-child {
            font-size: 23px;
        }

        #Slider #content p:nth-child(2) {
            width: 60%;
            font-size: 12px;
        }

        #Slider #content button {
            width: 120px;
            height: 50px;
            font-size: 12px;

        }
    }

    @media screen and (max-width: 500px) {
        #Slider .mysliders {
            height: 350px;
        }

        #Slider #content p:first-child {
            font-size: 18px;
        }

        #Slider #content p:nth-child(2) {
            width: 100%;
            font-size: 10px;
            margin-top: 10px;
        }

        #Slider #content button {
            width: 100px;
            height: 40px;
            font-size: 10px;
            margin-top: 30px;

        }
    }

    @media screen and (max-width: 425px) {
        #Slider .mysliders {
            height: 300px;
        }

        #Slider #content p:first-child {
            font-size: 14px;
            width: 100%;
        }

        #Slider #content p:nth-child(2) {
            width: 100%;
            font-size: 8px;
            margin-top: 7px;
        }

        #Slider #content button {
            width: 100px;
            height: 40px;
            font-size: 10px;
            margin-top: 20px;
        }

        #Slider a {
            font-size: 25px;
            height: 40px;
            width: 40px;
        }

        #Slider #indicators {
            margin-top: -20px;
        }

        #Slider #indicators .dot {
            width: 20px;
            height: 6px;
        }

        #Slider #indicators .active, #Slider #indicators .dot:hover {
            width: 30px;
        }
    }

    @media screen and (max-width: 375px) {
        #Slider .mysliders {
            height: 250px;
        }

        #Slider #content p:nth-child(2) {
            width: 100%;
            font-size: 8px;
            margin-top: 5px;
        }

        #Slider #content button {
            width: 80px;
            height: 25px;
            font-size: 8px;
            margin-top: 10px;
        }
    }


</style>