<template lang="">
    <div id="mainBlock">
        <div id="topHeading">
            <p>what we do ?</p>
            <p>Our Services</p>
            <p id="bar"></p>
        </div>
        <div id="secondPortion">
            <div class="forSetup">
                <div id="service1" class="services">
                    <div id="imgBox"></div>
                    <p id="heading">Domestic <br> construction</p>
                    <p id="para">Transforming Houses into Homes: Your Trusted Partner for Domestic Construction Projects. Evergreen Corporation delivers excellence in every aspect, ensuring your dream home becomes a reality. <br><br></p>
                    <p id="moreDetailbtn"><a href="#">More Details <svg xmlns="http://www.w3.org/2000/svg" id="arrow-styling"  height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg></a></p>
                </div>
                <div id="service2" class="services">
                    <div id="imgBox"></div>
                    <p id="heading">Commercial <br> Construction</p>
                    <p id="para">Elevating Business Spaces: Expert Solutions for Commercial Construction Projects. Evergreen Corporation brings innovation and efficiency to every commercial endeavor, shaping spaces that inspire success.</p>
                    <p id="moreDetailbtn"><a href="#">More Details <svg xmlns="http://www.w3.org/2000/svg" id="arrow-styling"  height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg></a></p>
                </div>
            </div>
            <div class="forSetup">
                <div id="service3" class="services">
                    <div id="imgBox"></div>
                    <p id="heading">Landscaping <br><br></p>
                    <p id="para">Crafting Outdoor Oases: Evergreen Corporation is your go-to for expert landscaping services. We specialize in turning ordinary spaces into lush havens, employing a blend of creativity and precision.</p>
                    <p id="moreDetailbtn"><a href="#">More Details <svg xmlns="http://www.w3.org/2000/svg" id="arrow-styling"  height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg></a></p>
                </div>
                <div id="service4" class="services">
                    <div id="imgBox"></div>
                    <p id="heading">Renovation <br><br></p>
                    <p id="para">Revitalizing Spaces: Expert Renovation Solutions Tailored to Your Vision. Evergreen Corporation breathes new life into homes and businesses, delivering exceptional results that exceed expectations. <br><br></p>
                    <p id="moreDetailbtn"><a href="#">More Details <svg xmlns="http://www.w3.org/2000/svg" id="arrow-styling"  height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg></a></p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "OurServices"
}
</script>
<style>
    * {
        margin: 0;
        padding: 0;
        font-family: 'Poppins', sans-serif;
    }

    #mainBlock {
        /* height: 950px; */
        background-color: #f5f5f5;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 70px 10% !important;
        height: auto !important;
    }

    #mainBlock #topHeading {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 60px;
    }

    #mainBlock #topHeading p:first-child {
        font-size: 18px;
        color: #00A54D;
        margin-bottom: 5px;
    }

    #mainBlock #topHeading p:nth-child(2) {
        font-size: 40px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    #mainBlock #topHeading p:last-child {
        height: 6px;
        border-radius: 5px;
        width: 80px;
        background-color: #00A54D;
    }

    #mainBlock #secondPortion,
    #mainBlock #secondPortion .forSetup {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    #mainBlock #secondPortion .forSetup {
        margin: 0 0.5%;
        width: 50%;
    }

    #mainBlock #secondPortion .services {
        width: 50%;
        margin: 0 1%;
        /* height: 620px; */
        background-color: #fff;
        border-bottom: 5px solid #00A54D;
    }

    #mainBlock #secondPortion .services:hover {
        box-shadow: 0px 0px 24px 0px rgba(72, 72, 72, 0.1);
        -webkit-transition: box-shadow 0.3s, transform 0.3s;
        transition: box-shadow 0.3s, transform 0.3s;
    }


    #mainBlock #secondPortion #service1 #imgBox {
        background-image: url("../assets/domastic.png");
    }
    
    #mainBlock #secondPortion #service2 #imgBox {
        background-image: url("../assets/Comercial.png");
    }

    #mainBlock #secondPortion #service3 #imgBox {
        background-image: url("../assets/landscalling.png");
    }

    #mainBlock #secondPortion #service4 #imgBox {
        background-image: url("../assets/renovation.png");
    }

    #mainBlock #secondPortion .services #imgBox {
        width: 100%;
        height: 250px;
        /* border: 1px solid red; */
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        margin-bottom: 40px;
    }

    #mainBlock #secondPortion .services p {
        margin: 0px 40px;
    }
 
    #mainBlock #secondPortion .services p:nth-child(2) {
        font-size: 22px;
        margin-bottom: 40px;
        font-weight: 600;

    }

    #mainBlock #secondPortion .services p:nth-child(3) {
        font-size: 16px;
        color: #797978;
        text-align: justify;
        margin-bottom: 15px;
    }

    #mainBlock #secondPortion .services p:last-child {
        margin-bottom: 40px;
    }

    #mainBlock #secondPortion .services p:last-child a {
        text-decoration: none;
        color: #797978;
        font-size: 16px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    #mainBlock #secondPortion .services p:last-child a svg {
        fill: #797978;
        margin-left: 7px;
    }

    #mainBlock #secondPortion .services p:last-child a:hover {
        text-decoration: underline;
        color: #00A54D;
        font-weight: 600;
    }

    #mainBlock #secondPortion .services p:last-child a:hover svg {
        fill: #00A54D;
    }


    /* @media screen and (max-width: 1440px) {
        #mainBlock {
            height: 1200px
        }
    } */

    @media screen and (max-width: 1024px) {
        /* #mainBlock {
            height: 1550px
        } */

        #mainBlock #secondPortion {
            flex-direction: column;
        }

        #mainBlock #secondPortion .forSetup {
            margin: 20px auto;
        }
    }

    /* @media screen and (max-width: 900px) {
        #mainBlock {
            height: 1650px
        }
    }

    @media screen and (max-width: 768px) {
        #mainBlock {
            height: 1750px
        }
    } */
    
    @media screen and (max-width: 650px) {
        /* #mainBlock {
            height: 3000px
        } */

        #mainBlock #topHeading p:first-child {
            font-size: 14px;
        }

        #mainBlock #topHeading p:nth-child(2) {
            font-size: 30px;
            font-weight: 600;
            margin-bottom: 7px;
        }

        #mainBlock #topHeading p:last-child {
            height: 6px;
            border-radius: 5px;
            width: 60px;
            background-color: #00A54D;
        }

        #mainBlock #secondPortion .forSetup {
            flex-direction: column;
            margin: 0;
        }

        #mainBlock #secondPortion .forSetup .services {
            margin: 20px auto;
            width: 80%;
        }
    }

    @media screen and (max-width: 425px) {
        /* #mainBlock {
            height: 3100px
        } */

        #mainBlock #secondPortion .forSetup .services {
            width: 90%;
        }
    }

    /* @media screen and (max-width: 320px) {
        #mainBlock {
            height: 3500px
        }
    } */


</style>