<template>
    <footer>
        <div id="mainFooter">
            <div id="toAlign">
                <div class="toSet">

                    <div class="containers" id="container1">
                        <div id="logo">
                            <img src="../assets/logo.png" alt="EverGreen">
                            <p><span>Ever</span><span>Green</span></p>
                        </div>
                        <p id="text">Elevating Construction Excellence - Evergreen Corporation: Crafting a Sustainable Future with Innovative Construction Solutions.</p>
                        <div id="socialAccounts">
                            <div class="logos">
                                <a href="#">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" height="1.7em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"/></svg>
                                </a>
                            </div>
                            <div class="logos">
                                <a href="#">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" height="1.7em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/>
                                    </svg>
                                </a>
                            </div>
                            <div class="logos">
                                <a href="#">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" height="1.7em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/></svg>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="containers" id="container2">
                        <div class="heading"><a href="#">Our Company</a></div>
                        <ul>
                            <li><a href="#">Who We Are</a></li>
                            <li><a href="#">About Company</a></li>
                            <li><a href="#">Services We Provide</a></li>
                            <li><a href="#">What We Have Done</a></li>
                        </ul>
                        
                    </div>
                </div>
                <div class="toSet">
                    <div class="containers" id="container3">
                        <div class="heading"><a href="#">Our Services</a></div>
                        <ul>
                            <li>Architecure</li>
                            <li>Exterior Design</li>
                            <li>Landscape Design</li>
                            <li>Site Planning</li>
                        </ul>
                        
                    </div>
                    <div class="containers" id="container4">
                        <div class="heading"><a href="#">Contact Details</a></div>
                        <ul>
                            <li>info@evergreen.com</li>
                            <li>+92 300 5505502</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div id="copyright">
            <p>Copyright &copy; 2024 by EverGreen</p>
            <p>Powered by <a href="#">ZerothGen</a></p>
        </div>
    </footer>
</template>
<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'AppFooter'
}
</script>
<style>
    * {
        margin: 0;
        padding: 0;
        font-family: 'Poppins', sans-serif;
    }
    footer #mainFooter {
        height: 450px;
        /* width: 100%; */
        padding: 0px 7%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #2C2E33;
        color: #fff;
    }

    footer #mainFooter #toAlign {
        display: flex;
        justify-content: space-between;
        width: 100%;
        /* align-items: center; */
    }

    footer #mainFooter #container1 #logo {
        height: 70px;
        display: flex;
        align-items: center;
    }

    footer #mainFooter #container1 #logo p span:first-child {
        color: #29ABE2;
    }

    footer #mainFooter #container1 #logo p span:last-child {
        color: #00A54D;
    }

    footer #mainFooter .toSet .containers {
        width: 50% !important;
        margin: 0px 3%;
    }

    .toSet {
        width: 50%;
        display: flex;
        justify-content: space-between;
        align-items: start;
    }

    footer #mainFooter .containers .heading {
        border-bottom: 1px solid #00A54D;
        padding-bottom: 15px;
        margin-bottom: 30px;
        position: relative;
    }

    footer #mainFooter .containers .heading::after {
        position: absolute;
        content: "";
        width: 70px;
        height: 30px;
        left: 0;
        bottom: 0;
        border-bottom: 4px solid #00A54D;
        z-index: 10;
    }

    footer #mainFooter .containers .heading a {
        font-size: 20px;
        font-weight: 600;
        color: #fff;
        text-decoration: none;
    }

    footer #mainFooter .containers a {
        text-decoration: none;
        color: #BFBFBF;
    }
    
    footer #mainFooter .containers ul {
        list-style: none;
    }

    footer #mainFooter .containers ul li {
        color: #BFBFBF;
        margin-bottom: 10px;
        font-size: 16px;
    }

    footer #mainFooter .containers ul li a:hover {
        color: #fff;
        text-decoration: underline;
    }

    footer #mainFooter #container1 #logo {
        margin-bottom: 30px;
    }

    footer #mainFooter #container1 #logo img {
        height: 60px;
        width: auto;
        margin-right: 20px;
    }

    footer #mainFooter #container1 #logo p {
        font-size: 30px;
        font-weight: 600;
    }

    footer #mainFooter #container1 #socialAccounts {
        display: flex;

    }

    footer #mainFooter #container1 #text {
        margin-bottom: 30px;
        color: #BFBFBF;
        text-align: justify;
    }

    footer #mainFooter #container1 #socialAccounts .logos {
        height: 50px;
        width: 50px;
        background-color: #232121;
        margin-right: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    footer #mainFooter #container1 #socialAccounts .logos a {
        height: 50px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    footer #mainFooter #container1 #socialAccounts .logos a:hover svg {
        fill: #00A54D;
    }

    footer #copyright {
        height: 70px;
        padding: 0px 7%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #232121;
        color: #fff;
    }

    footer #copyright a {
        color: #00A54D;
        font-weight: 600;
        text-decoration: none;
    }

    footer #copyright a:hover {
        text-decoration: underline;
    }

    

    @media screen and (max-width: 1440px) {
        footer #mainFooter #container1 #logo img {
            height: 35px;
            width: auto;
            margin-right: 20px;
        }
    }
    
    @media screen and (max-width: 1100px) {
        footer #mainFooter #container1 #logo {
            margin-bottom: 10px;
        }

        footer #mainFooter #container1 #logo img {
            height: 30px;
            width: auto;
            margin-right: 20px;
        }

        footer #mainFooter #container1 #logo p {
            font-size: 23px;
        }

        footer #mainFooter .containers ul li,
        footer #mainFooter #container1 #text {
            font-size: 14px;
        }

        footer #mainFooter .containers .heading a {
            font-size: 18px;
        }

        footer #mainFooter #container1 #socialAccounts .logos,
        footer #mainFooter #container1 #socialAccounts .logos a {
            height: 40px;
            width: 40px;
        }

        footer #mainFooter #container1 #socialAccounts .logos a svg {
            height: 1.4em;
        }
    }

    @media screen and (max-width: 800px) {
        footer #mainFooter {
            height: 600px
        }
        footer #mainFooter #toAlign {
            flex-direction: column;
        }

        footer #mainFooter #toAlign .toSet {
            width: 100%;
            margin: 3% auto;
        }
    }

    @media screen and (max-width: 500px) {
        footer #mainFooter {
            height: 1000px

        }

        footer #mainFooter #toAlign .toSet {
            flex-direction: column;
            align-items: center;
            margin: 0%
        }

        footer #mainFooter #toAlign .toSet .containers {
            margin: 3% auto;
        }

        footer #mainFooter .containers .heading a {
            font-size: 16px;
        }

        footer #copyright {
            flex-direction: column;
            padding: 15px auto;
            justify-content: center;
            align-items: center;
        }

    }
</style>