<template>
    <div>
        <navigationBar pageName="Projects"/>
        <OurProjects />
        <Footers />
    </div>
</template>
<script>
import OurProjects from '../components/OurProjects.vue';
import navigationBar from '../components/navigationBar.vue';
import Footers from '../components/AppFooter.vue';
export default {
    name: "ProjectsPage",
    components: {
        navigationBar,
        OurProjects,
        Footers
    }
}
</script>
<style>
    
</style>