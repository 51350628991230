<template lang="">
    <div>
        <Slider />
        <WelcomeComp />
        <OurServices />
        <HomeMoreDetails />
        <MissionVision />
        <WeOffer />
        <FollowUs />
        <OurProjects />
        <FreeConsultPart heading="We Provide the Best Service in Industry" para="Lorem" btnT="Contact Us Today" />
        <Footers />
    </div>
</template>
<script>
import Slider from '../components/Slider.vue';
import OurServices from '../components/OurServices.vue';
import OurProjects from '../components/OurProjects.vue';
import FreeConsultPart from '../components/GetYourFreeConsultation.vue';
import WeOffer from '../components/WeOffer.vue';
import WelcomeComp from '../components/WelcomeComp.vue';
import MissionVision from '../components/MissionVision.vue';
import HomeMoreDetails from '../components/HomeMoreDetails.vue';
import FollowUs from '../components/FollowUs.vue';
import Footers from '../components/AppFooter.vue';
export default {
    components: {
        Slider,
        OurServices,
        OurProjects,
        FreeConsultPart,
        WeOffer,
        WelcomeComp,
        MissionVision,
        HomeMoreDetails,
        FollowUs,
        Footers
    }
}
</script>
<style lang="">
    
</style>