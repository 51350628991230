<template lang="">
    <div id="ourProjects">
        <div id="topHeading">
            <p>what we do ?</p>
            <p>Our Projects</p>
            <p id="bar"></p>
        </div>

        <div id="secondPortion">
            <div id="project1" class="projects">
                <div id="imgBox"></div>
                <p id="boxP">Project 1</p>
                <div id="content">
                    <p id="heading">
                        A Fully Visual way to build websites using Wordpress
                    </p>
                    <p id="para">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dicta facere ex itaque natus! Voluptates, officiis.
                    </p>
                    <p id="moreDBtn"><a href="#">More Details <svg xmlns="http://www.w3.org/2000/svg" id="arrow-styling"  height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg></a></p>
                </div>

            </div>
            <div id="project2" class="projects">
                <div id="imgBox"></div>
                <p id="boxP">Project 1</p>
                <div id="content">
                    <p id="heading">
                        A Fully Visual way to build websites using Wordpress
                    </p>
                    <p id="para">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dicta facere ex itaque natus! Voluptates, officiis.
                    </p>
                    <p id="moreDBtn"><a href="#">More Details <svg xmlns="http://www.w3.org/2000/svg" id="arrow-styling"  height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg></a></p>
                </div>

            </div>
            <div id="project3" class="projects">
                <div id="imgBox"></div>
                <p id="boxP">Project 1</p>
                <div id="content">
                    <p id="heading">
                        A Fully Visual way to build websites using Wordpress
                    </p>
                    <p id="para">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dicta facere ex itaque natus! Voluptates, officiis.
                    </p>
                    <p id="moreDBtn"><a href="#">More Details <svg xmlns="http://www.w3.org/2000/svg" id="arrow-styling"  height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg></a></p>
                </div>

            </div>
        </div>

    </div>
</template>
<script>
export default {
    name: "OurProjects"
}
</script>
<style>
* {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
}

#ourProjects {
    /* height: 950px; */
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 70px 10%;
    height: auto !important;
}

#ourProjects #topHeading {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
}

#ourProjects #topHeading p:first-child {
    font-size: 18px;
    color: #00A54D;
    margin-bottom: 5px;
}

#ourProjects #topHeading p:nth-child(2) {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 10px;
}

#ourProjects #topHeading p:last-child {
    height: 6px;
    border-radius: 5px;
    width: 80px;
    background-color: #00A54D;
}

#ourProjects #secondPortion {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

#ourProjects #secondPortion .projects {
    width: 30%;
    margin: 0 1%;
    /* height: 620px; */
    background-color: #fff;
    border-bottom: 5px solid #00A54D;
    position: relative;
}

#ourProjects #secondPortion .projects:hover {
    box-shadow: 0px 0px 24px 0px rgba(72, 72, 72, 0.1);
    -webkit-transition: box-shadow 0.3s, transform 0.3s;
    transition: box-shadow 0.3s, transform 0.3s;
}



#ourProjects #secondPortion .projects #boxP {
    background-color: #00A54D;
    color: white;
    width: 100px;
    text-align: center;
    padding: 5px 0px;
    position: absolute;
    top: 4%;
    left: 8%;
}

#ourProjects #secondPortion #project1 #imgBox {
    background-image: url("../assets/service1.png");
}

#ourProjects #secondPortion #project2 #imgBox {
    background-image: url("../assets/service2.png");
}

#ourProjects #secondPortion #project3 #imgBox {
    background-image: url("../assets/service3.png");
}

#ourProjects #secondPortion .projects #imgBox {
    width: 100%;
    height: 250px;
    /* border: 1px solid red; */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 40px;
}

#ourProjects #secondPortion .projects #content {
    /* border: 2px solid red; */
    padding-top: 40px;
    margin-top: -60px !important;
    background-color: #fff;
}

#ourProjects #secondPortion .projects #content {
    margin: 0px 40px;
    margin-bottom: 40px;
}

#ourProjects #secondPortion .projects #content p:nth-child(1) {
    font-size: 22px;
    margin-bottom: 40px;
    font-weight: 600;

}

#ourProjects #secondPortion .projects #content p:nth-child(2) {
    font-size: 16px;
    color: #797978;
    text-align: justify;
    margin-bottom: 15px;
}

#ourProjects #secondPortion .projects #content p:last-child a {
    text-decoration: none;
    color: #797978;
    font-size: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

#ourProjects #secondPortion .projects #content p:last-child a svg {
    fill: #797978;
    margin-left: 7px;
}

#ourProjects #secondPortion .projects #content p:last-child a:hover {
    text-decoration: underline;
    color: #00A54D;
    font-weight: 600;
}

#ourProjects #secondPortion .projects #content p:last-child a:hover svg {
    fill: #00A54D;
}

@media screen and (max-width: 1024px) {

    #ourProjects #secondPortion .projects #content p:nth-child(1) {
        font-size: 18px;
        margin-bottom: 30px;
    }
    
}

@media screen and (max-width: 768px) {

    #ourProjects #secondPortion {
        flex-direction: column;
        align-items: center;
    }

    #ourProjects #secondPortion .projects {
        width: 90%;
        margin: 20px 0;
    }
}

@media screen and (max-width: 450px) {
    #ourProjects #topHeading p:first-child {
        font-size: 16px;
    }

    #ourProjects #topHeading p:nth-child(2) {
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    #ourProjects #topHeading p:last-child {
        height: 6px;
        border-radius: 5px;
        width: 60px;
        background-color: #00A54D;
    }
}

@media screen and (max-width: 425px) {
    /* #ourProjects {
        
    } */
}
</style>