// router.js
import { createRouter, createWebHistory } from 'vue-router';

// Import your pages
import Home from './Pages/HomePage.vue';
import About from './Pages/AboutUsPage.vue';
import Contact from './Pages/ContactPage.vue';
import Services from './Pages/ServicesPage.vue';
import Projects from './Pages/ProjectsPage.vue';

// Define routes
const routes = [
  { path: '/', component: Home },
  { path: '/about', component: About },
  { path: '/contact', component: Contact },
  { path: '/services', component: Services },
  { path: '/projects', component: Projects }
];

// Create router instance
const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
