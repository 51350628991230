<template>
    <div id="FreeConsultation-Backgroundimage">

        <div id="grey-background"></div>

        <div id="Content">

            <div id="hr"></div>

            <div id="Bold-Text">
                <h4>{{ heading }}</h4>
            </div>

            <p id="central-text">
                 {{ para }}
            </p>

            <Button id="Buttons">{{ btnT }} <svg  xmlns="http://www.w3.org/2000/svg" id="arrow-styling" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --> <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg></Button>

        </div>
    </div>
</template>
<script>
export default {
    name: 'GetYourFreeConsultation',
    props: {
        heading: String,
        para: String,
        btnT: String
    }
}
</script>
<style scoped>
    *{
        font-family: 'Poppins', sans-serif;
    }
#FreeConsultation-Backgroundimage {
    /* margin: 50px 0px; */
    background-image: url('../assets/FreeConsultantbackgroundimage.png');
    background-position: top;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    height: 400px;
    position: relative;
}

#FreeConsultation-Backgroundimage #grey-background {
    background-color: #1A1A1A;
    opacity: 0.4;
    height: 400px;
    width: 100%;
}
#FreeConsultation-Backgroundimage #Content{
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
#FreeConsultation-Backgroundimage #Content #hr{
    border: 3px solid #00A54D;
    width: 60px;
}
#FreeConsultation-Backgroundimage #Content #Bold-Text{
    font-size: 40px;
    color: white;
    margin: 10px 0px;
    text-align: center;
}
#FreeConsultation-Backgroundimage #Content #central-text{
    font-size: 16px;
    color: white;
    text-align: center;
    margin: 0px 300px;
    margin-bottom: 30px;
}
#FreeConsultation-Backgroundimage #Content #Buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00A54D;
    fill: white;
    border: none;
    color: white;
    /* padding: 10px 25px; */
    height: 60px;
    width: 300px;
    text-align: center;
    font-size: 16px;
    margin: 30px 0px 0px;
}
#FreeConsultation-Backgroundimage #Content #Buttons:hover{
    cursor: pointer;
    border: 1px solid #00A54D;
    background-color: white;
    fill: black;
    color: black;
}
#FreeConsultation-Backgroundimage #Content #Buttons #arrow-styling{
    margin-left: 10px;
}

/* Responsive on pixels 1440px */
@media screen and (max-width: 1440px) {
    /* No need for responsiveness in this range */
}

/* Responsive on pixels 1024px */
@media screen and (max-width: 1024px) {
    #FreeConsultation-Backgroundimage #Content #Bold-Text{
    font-size: 35px;
    margin-left: 10px;
    margin-right: 10px;
}
#FreeConsultation-Backgroundimage #Content #central-text{
    font-size: 14px;
}
}

/* Responsive on pixels 768px */
@media screen and (max-width: 768px) {
    #FreeConsultation-Backgroundimage {
   
    height: 400px;
    
}
#FreeConsultation-Backgroundimage #grey-background {
    height: 400px;   
}
    #FreeConsultation-Backgroundimage #Content #Bold-Text{
    font-size: 30px;
    margin-left: 10px;
    margin-right: 10px;
}
#FreeConsultation-Backgroundimage #Content #central-text{
    font-size: 12px;
    
}
#FreeConsultation-Backgroundimage #Content #Buttons{
    height: 50px;
    width: 225px;
    font-size: 12px;
}
}

/* Responsive on pixels 425px */
@media screen and (max-width: 425px) {
    #FreeConsultation-Backgroundimage #Content #Bold-Text{
        font-size: 25px;
        margin-left: 30px;
        margin-right: 30px;
    }
    #FreeConsultation-Backgroundimage #Content #central-text{
        font-size: 12px;
        margin: 0px 50px;
    }
    #FreeConsultation-Backgroundimage #Content #Buttons{
        height: 50px;
        width: 225px;
        font-size: 12px;
    }
}

/* Responsive on pixels 375px */
@media screen and (max-width: 375px) {
    #FreeConsultation-Backgroundimage #Content #Bold-Text{
        font-size: 25px;
        margin-left: 10px;
        margin-right: 10px;
    }
    #FreeConsultation-Backgroundimage #Content #central-text{
        font-size: 10px;
    }

}

/* Responsive on pixels 320px */
</style>