<template>
    <div id="navigation">
        <div id="header">
            <div id="color"></div>
            <p>{{ pageName }}</p>
        </div>
        <div id="breadcrump">
            <p><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M543.8 287.6c17 0 32-14 32-32.1c1-9-3-17-11-24L512 185V64c0-17.7-14.3-32-32-32H448c-17.7 0-32 14.3-32 32v36.7L309.5 7c-6-5-14-7-21-7s-15 1-22 8L10 231.5c-7 7-10 15-10 24c0 18 14 32.1 32 32.1h32v69.7c-.1 .9-.1 1.8-.1 2.8V472c0 22.1 17.9 40 40 40h16c1.2 0 2.4-.1 3.6-.2c1.5 .1 3 .2 4.5 .2H160h24c22.1 0 40-17.9 40-40V448 384c0-17.7 14.3-32 32-32h64c17.7 0 32 14.3 32 32v64 24c0 22.1 17.9 40 40 40h24 32.5c1.4 0 2.8 0 4.2-.1c1.1 .1 2.2 .1 3.3 .1h16c22.1 0 40-17.9 40-40V455.8c.3-2.6 .5-5.3 .5-8.1l-.7-160.2h32z"/></svg><a href="#">Home</a><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/></svg></p>
            <p>{{ pageName }}</p>
        </div>
    </div>
</template>
<script>
export default {
    name: "navigationBar",
    props: {
        pageName: String
    }
}
</script>
<style>
    * {
        margin: 0;
        padding: 0;
        font-family: 'Poppins', sans-serif;
    }
    #navigation #header {
        height: 350px;
        background-image: url("../assets/navigationBarImg.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top;
        position: relative;
    }

    #navigation #header #color {
        background-color: rgb(0, 0, 0, 0.6);
        height: 100%;
    }

    #navigation #header p {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 100%;
        font-size: 37px;
        color: white;
        font-weight: 600;
    }

    #navigation #breadcrump {
        height: 60px;
        background-color: #232121;
        display: flex;
        align-items: center;
        color: #DAD8D8;
        fill: #DAD8D8;
        font-size: 16px;
        font-weight: 500;
    }

    #navigation #breadcrump p:first-child {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-right: 1px solid rgb(218, 216, 216, 0.2);
        height: 100%;
        width: 50%;
        text-align: right;
        padding-right: 20px;
    }

    #navigation #breadcrump p:first-child a {
        margin: 0px 5px;
        text-decoration: none;
        color: #DAD8D8;
    }

    #navigation #breadcrump p:first-child a:hover { text-decoration: underline; }
    
    #navigation #breadcrump p:last-child {
        display: flex;
        align-items: center;
        height: 100%;
        border-left: 1px solid rgb(218, 216, 216, 0.2);
        padding-left: 20px;
        color: #00A54D;

    }
</style>