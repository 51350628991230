<template >
  <div>
    <navigationBar pageName="About Us" />
    <OurStory />
    <MissionVision/>
    <Footers />
  </div>
  <!-- <FreeConsultPart heading="We Provide the Best Service in Industry" para="Lorem" btnT="Contact Us Today" /> -->
</template>
<script>
import navigationBar from '../components/navigationBar.vue'
import OurStory from '../components/OurStory.vue';
import MissionVision from '../components/MissionVision.vue';
import Footers from '../components/AppFooter.vue';
// import FreeConsultPart from '../components/GetYourFreeConsultation.vue';


export default {
  name: "AboutUs",
  components: {
    navigationBar,
    OurStory,
    // FreeConsultPart,
    MissionVision,
    Footers
  }
}

</script>
<style ></style>