<template >
    <div id="Our-Specialities">

        <div id="Textures">

            <div id="Bold-Text">
                <b>Our Specialties</b>
            </div>

            <div id="hr"></div>

            <div id="Central-Text">
                Unveiling our exceptional specialties that redefine construction excellence and sustainable innovation in
                every project we undertake.
            </div>
        </div>

        <div id="Grids">

            <div id="Row1">

                <div class="Column1">
                    <div class="icons">
                        <svg xmlns="http://www.w3.org/2000/svg" height="2em"
                            viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                            <path
                                d="M184 0c30.9 0 56 25.1 56 56V456c0 30.9-25.1 56-56 56c-28.9 0-52.7-21.9-55.7-50.1c-5.2 1.4-10.7 2.1-16.3 2.1c-35.3 0-64-28.7-64-64c0-7.4 1.3-14.6 3.6-21.2C21.4 367.4 0 338.2 0 304c0-31.9 18.7-59.5 45.8-72.3C37.1 220.8 32 207 32 192c0-30.7 21.6-56.3 50.4-62.6C80.8 123.9 80 118 80 112c0-29.9 20.6-55.1 48.3-62.1C131.3 21.9 155.1 0 184 0zM328 0c28.9 0 52.6 21.9 55.7 49.9c27.8 7 48.3 32.1 48.3 62.1c0 6-.8 11.9-2.4 17.4c28.8 6.2 50.4 31.9 50.4 62.6c0 15-5.1 28.8-13.8 39.7C493.3 244.5 512 272.1 512 304c0 34.2-21.4 63.4-51.6 74.8c2.3 6.6 3.6 13.8 3.6 21.2c0 35.3-28.7 64-64 64c-5.6 0-11.1-.7-16.3-2.1c-3 28.2-26.8 50.1-55.7 50.1c-30.9 0-56-25.1-56-56V56c0-30.9 25.1-56 56-56z" />
                        </svg>
                    </div>
                    <div class="heading"><b>Personalized Approach</b></div>
                    <div class="detail">We believe in personalized solutions that match your unique preferences and
                        requirements. Our agents take the time to listen to your needs and tailor their services to exceed
                        your expectations.</div>
                </div>

                <div class="Column2">
                    <div class="icons">
                        <svg xmlns="http://www.w3.org/2000/svg" height="2em"
                            viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                            <path
                                d="M184 0c30.9 0 56 25.1 56 56V456c0 30.9-25.1 56-56 56c-28.9 0-52.7-21.9-55.7-50.1c-5.2 1.4-10.7 2.1-16.3 2.1c-35.3 0-64-28.7-64-64c0-7.4 1.3-14.6 3.6-21.2C21.4 367.4 0 338.2 0 304c0-31.9 18.7-59.5 45.8-72.3C37.1 220.8 32 207 32 192c0-30.7 21.6-56.3 50.4-62.6C80.8 123.9 80 118 80 112c0-29.9 20.6-55.1 48.3-62.1C131.3 21.9 155.1 0 184 0zM328 0c28.9 0 52.6 21.9 55.7 49.9c27.8 7 48.3 32.1 48.3 62.1c0 6-.8 11.9-2.4 17.4c28.8 6.2 50.4 31.9 50.4 62.6c0 15-5.1 28.8-13.8 39.7C493.3 244.5 512 272.1 512 304c0 34.2-21.4 63.4-51.6 74.8c2.3 6.6 3.6 13.8 3.6 21.2c0 35.3-28.7 64-64 64c-5.6 0-11.1-.7-16.3-2.1c-3 28.2-26.8 50.1-55.7 50.1c-30.9 0-56-25.1-56-56V56c0-30.9 25.1-56 56-56z" />
                        </svg>
                    </div>
                    <div class="heading"><b>Extensive Property Portfolio</b></div>
                    <div class="detail"> Explore a wide range of properties in diverse locations. Whether you're interested
                        in urban living, suburban comfort, or serene countryside retreats, we have something for everyone.
                    </div>
                </div>

                <div class="Column3">
                    <div class="icons">
                        <svg xmlns="http://www.w3.org/2000/svg" height="2em"
                            viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                            <path
                                d="M184 0c30.9 0 56 25.1 56 56V456c0 30.9-25.1 56-56 56c-28.9 0-52.7-21.9-55.7-50.1c-5.2 1.4-10.7 2.1-16.3 2.1c-35.3 0-64-28.7-64-64c0-7.4 1.3-14.6 3.6-21.2C21.4 367.4 0 338.2 0 304c0-31.9 18.7-59.5 45.8-72.3C37.1 220.8 32 207 32 192c0-30.7 21.6-56.3 50.4-62.6C80.8 123.9 80 118 80 112c0-29.9 20.6-55.1 48.3-62.1C131.3 21.9 155.1 0 184 0zM328 0c28.9 0 52.6 21.9 55.7 49.9c27.8 7 48.3 32.1 48.3 62.1c0 6-.8 11.9-2.4 17.4c28.8 6.2 50.4 31.9 50.4 62.6c0 15-5.1 28.8-13.8 39.7C493.3 244.5 512 272.1 512 304c0 34.2-21.4 63.4-51.6 74.8c2.3 6.6 3.6 13.8 3.6 21.2c0 35.3-28.7 64-64 64c-5.6 0-11.1-.7-16.3-2.1c-3 28.2-26.8 50.1-55.7 50.1c-30.9 0-56-25.1-56-56V56c0-30.9 25.1-56 56-56z" />
                        </svg>
                    </div>
                    <div class="heading"><b>Market Expertise</b></div>
                    <div class="detail">With years of experience in the real estate industry, our experts possess in-depth
                        knowledge of local market trends. We provide you with insights that empower you to make informed
                        decisions.</div>
                </div>

            </div>

            <div id="Row2">

                <div class="Column1">
                    <div class="icons">
                        <svg xmlns="http://www.w3.org/2000/svg" height="2em"
                            viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                            <path
                                d="M184 0c30.9 0 56 25.1 56 56V456c0 30.9-25.1 56-56 56c-28.9 0-52.7-21.9-55.7-50.1c-5.2 1.4-10.7 2.1-16.3 2.1c-35.3 0-64-28.7-64-64c0-7.4 1.3-14.6 3.6-21.2C21.4 367.4 0 338.2 0 304c0-31.9 18.7-59.5 45.8-72.3C37.1 220.8 32 207 32 192c0-30.7 21.6-56.3 50.4-62.6C80.8 123.9 80 118 80 112c0-29.9 20.6-55.1 48.3-62.1C131.3 21.9 155.1 0 184 0zM328 0c28.9 0 52.6 21.9 55.7 49.9c27.8 7 48.3 32.1 48.3 62.1c0 6-.8 11.9-2.4 17.4c28.8 6.2 50.4 31.9 50.4 62.6c0 15-5.1 28.8-13.8 39.7C493.3 244.5 512 272.1 512 304c0 34.2-21.4 63.4-51.6 74.8c2.3 6.6 3.6 13.8 3.6 21.2c0 35.3-28.7 64-64 64c-5.6 0-11.1-.7-16.3-2.1c-3 28.2-26.8 50.1-55.7 50.1c-30.9 0-56-25.1-56-56V56c0-30.9 25.1-56 56-56z" />
                        </svg>
                    </div>
                    <div class="heading"><b>Professional Guidance</b></div>
                    <div class="detail">Navigating the real estate landscape can be daunting, but our team is here to help.
                        We assist you with legalities, negotiations, and paperwork, ensuring a smooth and secure
                        transaction.</div>
                </div>

                <div class="Column2">
                    <div class="icons">
                        <svg xmlns="http://www.w3.org/2000/svg" height="2em"
                            viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                            <path
                                d="M184 0c30.9 0 56 25.1 56 56V456c0 30.9-25.1 56-56 56c-28.9 0-52.7-21.9-55.7-50.1c-5.2 1.4-10.7 2.1-16.3 2.1c-35.3 0-64-28.7-64-64c0-7.4 1.3-14.6 3.6-21.2C21.4 367.4 0 338.2 0 304c0-31.9 18.7-59.5 45.8-72.3C37.1 220.8 32 207 32 192c0-30.7 21.6-56.3 50.4-62.6C80.8 123.9 80 118 80 112c0-29.9 20.6-55.1 48.3-62.1C131.3 21.9 155.1 0 184 0zM328 0c28.9 0 52.6 21.9 55.7 49.9c27.8 7 48.3 32.1 48.3 62.1c0 6-.8 11.9-2.4 17.4c28.8 6.2 50.4 31.9 50.4 62.6c0 15-5.1 28.8-13.8 39.7C493.3 244.5 512 272.1 512 304c0 34.2-21.4 63.4-51.6 74.8c2.3 6.6 3.6 13.8 3.6 21.2c0 35.3-28.7 64-64 64c-5.6 0-11.1-.7-16.3-2.1c-3 28.2-26.8 50.1-55.7 50.1c-30.9 0-56-25.1-56-56V56c0-30.9 25.1-56 56-56z" />
                        </svg>
                    </div>
                    <div class="heading"><b>Customer Satisfaction</b></div>
                    <div class="detail">Our clients are at the heart of everything we do. We measure our success by your
                        satisfaction, and we're proud to have helped numerous individuals and families find their perfect
                        homes.</div>
                </div>

                <div class="Column3">
                    <div class="icons">
                        <svg xmlns="http://www.w3.org/2000/svg" height="2em"
                            viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                            <path
                                d="M184 0c30.9 0 56 25.1 56 56V456c0 30.9-25.1 56-56 56c-28.9 0-52.7-21.9-55.7-50.1c-5.2 1.4-10.7 2.1-16.3 2.1c-35.3 0-64-28.7-64-64c0-7.4 1.3-14.6 3.6-21.2C21.4 367.4 0 338.2 0 304c0-31.9 18.7-59.5 45.8-72.3C37.1 220.8 32 207 32 192c0-30.7 21.6-56.3 50.4-62.6C80.8 123.9 80 118 80 112c0-29.9 20.6-55.1 48.3-62.1C131.3 21.9 155.1 0 184 0zM328 0c28.9 0 52.6 21.9 55.7 49.9c27.8 7 48.3 32.1 48.3 62.1c0 6-.8 11.9-2.4 17.4c28.8 6.2 50.4 31.9 50.4 62.6c0 15-5.1 28.8-13.8 39.7C493.3 244.5 512 272.1 512 304c0 34.2-21.4 63.4-51.6 74.8c2.3 6.6 3.6 13.8 3.6 21.2c0 35.3-28.7 64-64 64c-5.6 0-11.1-.7-16.3-2.1c-3 28.2-26.8 50.1-55.7 50.1c-30.9 0-56-25.1-56-56V56c0-30.9 25.1-56 56-56z" />
                        </svg>
                    </div>
                    <div class="heading"><b>Sustainable Construction</b></div>
                    <div class="detail">
                        Leading the way in adopting sustainable practices, championing a greener and more responsible future
                        for construction. Explore Evergreen Corporation's commitment.
                    </div>
                </div>

            </div>

        </div>

    </div>
</template>
<script>
export default {
    name: 'OurSpecialities'
}
</script>
<style Scoped>
* {
    margin: 0%;
    padding: 0%;
    font-family: 'Poppins', sans-serif;
}

#Our-Specialities {
    height: auto;
    padding: 70px 10%;
    width: 100%;
}

#Our-Specialities #Textures {
    padding-top: 55px;
    /* margin-left: 193px; */
    margin-bottom: 15px;
}

#Our-Specialities #Bold-Text {
    font-size: 35px;
}

#Our-Specialities #hr {
    border: 3px solid #00A54D;
    width: 60px;
    margin: 15px 0px;
}

#Our-Specialities #Central-Text {
    color: #5A5A5A;
    width: 40%;
    font-size: 13px;
    margin-bottom: 15px;
}

#Our-Specialities #Grids {
    margin-top: 20px;
}

#Our-Specialities #Row1,
#Our-Specialities #Row2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#Row1,
#Row2 {
    height: auto;
}

.Column1,
.Column2,
.Column3 {
    /* height: auto; */
    width: 30%;
    margin: auto 10px;
    margin-bottom: 5%;
}

.icons {
    fill: #00A54D;
    margin-bottom: 10px;
}

.heading {
    font-size: 16px;
    margin-bottom: 5px;
}

.detail {
    font-size: 13px;
    color: #5A5A5A;
    text-align: justify;
}



/* Responsiveness on pixels 768 */
@media screen and (max-width: 768px) {
    #Our-Specialities #Central-Text {
        color: #5A5A5A;
        width: 60%;
        font-size: 13px;
        margin-bottom: 15px;
    }

    #Our-Specialities #Grids {
        display: flex;
        align-items: center;
        /* justify-content: center; */
        /* margin: 20px 190px; */
        width: 100%;
    }

    #Our-Specialities #Row1,
    #Our-Specialities #Row2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .Column1,
.Column2,
.Column3 {
    /* height: auto; */
    width: 90%;
    margin: auto 10px;
    margin-bottom: 5%;
}

@media screen and (max-width: 450px) {
    #Our-Specialities #Grids {
        display: flex;
        align-items: center;
        flex-direction: column;
        /* justify-content: center; */
        /* margin: 20px 190px; */
        width: 100%;
    }
}
}</style>