<template>
    <div id="MissionVision">
        <div id="green-platform"></div>

        <div id="Content-MissionVision">
            <div id="Bold-Heading">
                <b>Vision & Mission</b>
            </div>

            <div id="hr"></div>

            <div id="Central-Text">
                At Evergreen Corporation, our vision is the blueprint for our mission – to redefine the construction landscape by pioneering sustainable practices, fostering innovation, and delivering excellence in every project. Our vision encompasses more than just structures; it encompasses a commitment to building a greener, more prosperous future. Our mission is simple: to transform that vision into reality. With an unwavering dedication to quality, sustainability, and client satisfaction, we are poised to be your partner in constructing a world where lasting impact meets exceptional craftsmanship. Discover the Evergreen Corporation difference as we build today for a better tomorrow.
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'MissionVision'
}
</script>
<style scoped>
    *{
        margin: 0%;
        padding: 0%;
        font-family: 'Poppins', sans-serif;
        color: white;
    }
    #MissionVision{
        width: 100%;
        height: 400px;
        background-image: url('../assets/FreeConsultantbackgroundimage.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        position: relative;
    }
    #MissionVision #green-platform{
        width: 100%;
        height: 400px;
        /* background-color: rgb(0, 165, 77, 0.6); */
        background-color: #1A1A1A;
        opacity: 0.6;
    }
    #MissionVision #Content-MissionVision{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        transform: translate(0%, -50%);
    }
    #MissionVision #Content-MissionVision #Bold-Heading{
        font-size: 40px;
        margin: 10px 0px;
    }
    #MissionVision #Content-MissionVision #hr{
        border: 3px solid white;
        width: 15%;
    }
    #MissionVision #Content-MissionVision #Central-Text{
        margin: 20px 10%;
        font-size: 15px;
        text-align: center;
    }

    /* Responsive on pixels 1440px */
@media screen and (max-width: 1440px) {
    /* No Need for any change */
}

/* Responsive on pixels 1024px */
@media screen and (max-width: 1024px) {
    /* No Need for any change */
}

/* Responsive on pixels 768px */
@media screen and (max-width: 768px) {
    #MissionVision{
        height: 500px;
    }
    #MissionVision #green-platform{
        height: 500px;
    }
}

/* Responsive on pixels 425px */
@media screen and (max-width: 425px) {
    #MissionVision{
        height: 550px;
    }
     #MissionVision #green-platform{
        height: 550px;
    }
   #MissionVision #Content-MissionVision #Bold-Heading{
        font-size: 25px;
    }
    #MissionVision #Content-MissionVision #Central-Text{
        font-size: 13px; 
    }
}

/* Responsive on pixels 375px */
@media screen and (max-width: 375px) {
    /* No need for change */
}

/* Responsive on pixels 320px */
</style>