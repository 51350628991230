<template>
    <div>
        <div id="TopMenu">
            <div id="left">
                <a href="#"><img src="./assets/logo.png" alt=""></a>
                <span>Ever</span>
                <span>Green</span>
            </div>
            <div id="right">
                <div class="contactInfo">
                    <div id="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1.2em" fill="#fff" viewBox="0 0 512 512">
                            <path
                                d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
                        </svg>
                    </div>
                    <div id="infoContact">
                        <p>Email</p>
                        <p>info@evergreen.com</p>
                    </div>
                </div>
                <div class="contactInfo">
                    <div id="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1.2em" fill="#fff" viewBox="0 0 512 512">
                            <path
                                d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                        </svg>
                    </div>
                    <div id="infoContact">
                        <p>Call Now</p>
                        <p>+92 300 5505502</p>
                    </div>
                </div>
                <div class="contactInfo">
                    <div id="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1.2em" fill="#fff" viewBox="0 0 512 512">
                            <path
                                d="M256 0a256 256 0 1 1 0 512A256 256 0 1 1 256 0zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
                        </svg>
                    </div>
                    <div id="infoContact">
                        <p>Open Hours</p>
                        <p>24/7</p>
                    </div>
                </div>
            </div>
        </div>
        <div id="bottomMenu">
            <div id="left">
                <a id="menuButton" @click="showMenu()">
                    <svg xmlns="http://www.w3.org/2000/svg" height="1.3em" viewBox="0 0 448 512">
                        <path
                            d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
                    </svg> <span>Home</span>
                </a>
                <ul id="Items" class="menuItems" :class="this.showMobileMenu ? 'open-menu' : 'closed-menu'">
                    <li><router-link to="/">Home</router-link></li>
                    <li><router-link to="/about">About Us</router-link></li>
                    <li><router-link to="/services">Services</router-link></li>
                    <li><router-link to="/projects">Projects</router-link></li>
                    <li><router-link to="/contact">Contact Us</router-link></li>
                </ul>
            </div>
            <div id="right">
                <a href="#"><svg xmlns="http://www.w3.org/2000/svg" height="1.2em" fill="#000" viewBox="0 0 320 512">
                        <path
                            d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
                    </svg></a>
                <a href="#"><svg xmlns="http://www.w3.org/2000/svg" height="1.2em" fill="#000" viewBox="0 0 448 512">
                        <path
                            d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                    </svg></a>
                <a href="#"><svg xmlns="http://www.w3.org/2000/svg" height="1.2em" fill="#000" viewBox="0 0 448 512">
                        <path
                            d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
                    </svg></a>
            </div>
        </div>
        <router-view></router-view>
        <!-- <component :is="currentView" /> -->
        <Footers />
    </div>
</template>
<script>
import Footers from './components/AppFooter.vue';
import router from './router';
export default {
    name: "App",
    component: {
        Footers
    },
    setup() {
        return {
            router
        };
    },
    data() {
        return {
            showMobileMenu: false,
        };
    },
    methods: {
        showMenu() {
            this.showMobileMenu = !this.showMobileMenu;
        },
    },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
}

#TopMenu {
    display: flex;
    height: 100px;
    /* width: 100%; */
    justify-content: space-between;
    align-items: center;
    padding: 0px 7.8125%;
    /* 150px/1920 */
}

#TopMenu #left {
    display: flex;
    align-items: center;
    height: 100%;
}

#TopMenu #left img {
    height: 60px;
    margin-right: 15px;
}

#TopMenu #left span {
    font-size: 30px;
    font-weight: 600;
}

#TopMenu #left span:nth-child(2) {
    color: #29ABE2;
    /* font-size: 40px; */
}

#TopMenu #left span:last-child {
    color: #00A54D;
}

#TopMenu #right {
    display: flex;
    height: 100%;
    flex-direction: row;
}

.contactInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: 100%;
    margin-left: 30px;
}

.contactInfo #icon {
    height: 40px;
    width: 40px;
    background-color: #00A54D;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    margin-right: 10px;
}

.contactInfo #infoContact p {
    font-size: 14px;
    color: #252323;
}

.contactInfo #infoContact p:first-child {
    font-weight: bold;
}

/* Bottom Menu */

#bottomMenu {
    background-color: #F6F6F6;
    height: 75px;
    padding: 0px 7.8125%;
    /* 150px/1920 */
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* position: absolute; */
    /* width: 100%; */
    position: sticky;
    top: 0;
    z-index: 11;
}


#bottomMenu a {
    color: #000;
    text-decoration: none;
}

#bottomMenu #left #menuButton {
    background-color: #fff;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    box-shadow: 0 0 9px rgb(34 34 34/14%);
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    display: none;
    position: absolute;
    z-index: 10;
    top: 15px
}

#bottomMenu #left #menuButton span {
    margin-left: 10px;
    /* margin-bottom: 5px; */
}

#bottomMenu #left .menuItems {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

#bottomMenu #left .menuItems li {
    margin-right: 2.5rem;
}

#bottomMenu #left .menuItems li a {
    font-size: .9375rem;
}

#bottomMenu #left .menuItems li a:hover {
    color: #00A54D;
    font-weight: 500;
}

#bottomMenu #right a {
    margin-left: 40px;
    height: 35px;
    width: 35px;
}

#bottomMenu #right a:hover svg {
    fill: #00A54D;
}

@media screen and (max-width: 800px) {
    #TopMenu #left img {
        height: 35px;
    }

    .contactInfo:last-child {
        display: none;
    }

    .contactInfo #infoContact p {
        font-size: 12px;
    }

    .contactInfo #icon {
        height: 30px;
        width: 30px;
    }

    .contactInfo #icon svg {
        height: .8em;
    }

    #bottomMenu #left #menuButton {
        display: block;
        z-index: 11;
    }

    /* #bottomMenu #left {
    } */

    #bottomMenu #left .menuItems {
        position: relative;
        list-style: none;
        /* left: 0px; */
        top: 218px;
        /* width: 100%; */
        padding: 10px;
        width: 400px;
        padding-bottom: 20px;
        flex-direction: column;
        /* background-color: #F6F6F6; */
        /* display: none; */
    }

    #bottomMenu #left .menuItems li {
        background-color: #F6F6F6;
        height: 70px !important;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        overflow: visible;
    }

    #bottomMenu #left .menuItems li:last-child a {
        border: none;
    }


    #bottomMenu #left .menuItems li a {
        padding: 0px 20px;
        border-bottom: 2px solid #00A54D;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        width: 90%;
    }

    .open-menu {
        opacity: 1;
        height: 150px;

        z-index: 10;
        /* display: block; */
    }

    .closed-menu {
        display: none;
        opacity: 0;
        height: 0;
        padding: 0;
        z-index: 0;
    }
}

@media screen and (max-width: 500px) {
    #bottomMenu #left {
        width: 60%;
    }

    #bottomMenu #right a {
        margin-left: 20px;
    }
}

@media screen and (max-width: 670px) {
    .contactInfo:first-child {
        display: none;
    }

    #bottomMenu #left .menuItems {
        width: 260px;
    }

    .contactInfo:nth-child(2) {
        display: none;
    }
}
</style>
