<template lang="">
    <div>
        <navigationBar pageName="Services"/>
        <OurServices />
        <OurSpecialities />
        <FollowUs/>
        <Footers />
    </div>
</template>
<script>
import navigationBar from '../components/navigationBar.vue'
import OurSpecialities from '@/components/OurSpecialities.vue';
import FollowUs from '../components/FollowUs.vue';
import OurServices from '../components/OurServices.vue';
import Footers from '../components/AppFooter.vue';
export default {
    name: "ServicesPage",
    components:{
        navigationBar,
        OurSpecialities,
        OurServices,
        FollowUs,
        Footers
    }
}
</script>
<style lang="">
    
</style>