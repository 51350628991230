<template>
    <div id="OurStory">

        <div id="ImageSection">
            <div id="BorderImage">
                <div id="MainImage"></div>
            </div>
        </div>

        <div id="TextSection">
            <div id="fAlign">
                <div id="BoldText">
                    <b>Our Story</b>
                </div>

                <div id="hr"></div>

                <div id="CentralText">
                    Evergreen Corporation, a quality name in the construction industry. We thrives on innovation. Our
                    dedication to staying at the forefront of advancements and sustainable practices drives us to find
                    smarter, more efficient ways to build. From utilizing the latest construction techniques to embracing
                    eco-friendly materials, we create structures that stand as testaments to modern ingenuity.
                </div>

                <div id="Bullets">
                    <div id="BulletSection1">
                        <p class="TextStyling">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" class="checkicon" height="1em"
                                    viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                    <path
                                        d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                                </svg>
                            </span>
                            <b>Professional Specialist</b>
                        </p>

                        <p class="TextStyling">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" class="checkicon" height="1em"
                                    viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                    <path
                                        d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                                </svg>
                            </span>
                            <b>Brilliant Ideas</b>
                        </p>
                    </div>

                    <div id="BulletSection2">
                        <p class="TextStyling">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" class="checkicon" height="1em"
                                    viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                    <path
                                        d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                                </svg>
                            </span>
                            <b>Precise Builders</b>
                        </p>

                        <p class="TextStyling">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" class="checkicon" height="1em"
                                    viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                    <path
                                        d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                                </svg>
                            </span>
                            <b>24/7 Assistance</b>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'OurStory'
}
</script>
<style scoped>
* {
    margin: 0%;
    padding: 0%;
    font-family: 'Poppins', sans-serif;
}

#OurStory {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 10%;
    margin-right: 10%;
    /* width: 100%; */
    height: auto;
}


#OurStory #ImageSection #BorderImage {
    height: 425px;
    width: 400px;
    border: 4px solid #00A54D;
}

#OurStory #ImageSection #BorderImage #MainImage {
    height: 425px;
    width: 400px;
    background-image: url('../assets/storyimg.png');
    background-position: center;
    background-size: cover;
    margin: -30px -30px;
}

#OurStory #TextSection {
    /* margin-left: 80px ; */
    margin-bottom: 30px;
    /* margin-right: 200px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 50px;
}

#OurStory #TextSection #BoldText {
    font-size: 30px;
}

#OurStory #TextSection #hr {
    border: 3px solid #00A54D;
    width: 60px;
    margin: 15px 0px;
}

#OurStory #TextSection #CentralText {
    line-height: 25px;
    font-size: 14px;
    margin-bottom: 10px;
    /* margin-right: 500px; */
    color: #5A5A5A;
}

#OurStory #TextSection #Bullets {
    display: flex;
    margin-bottom: 40px;
}

#OurStory #TextSection #Bullets #BulletSection2 {
    margin-left: 50px;
}

.TextStyling {
    display: flex;
    align-items: center;
    fill: #00A54D;
    margin: 10px 0px;
    font-size: 14px;
    color: #5A5A5A;
}

.checkicon {
    margin-right: 10px;
}


/* Responsive on pixels 1440px */
@media screen and (max-width: 1440px) {
    #OurStory #TextSection {
        margin-bottom: 0px;
    }

    #OurStory #TextSection #CentralText {
        line-height: 20px;
    }
}

/* Responsive on pixels 1024px */
@media screen and (max-width: 1024px) {
    #OurStory #ImageSection #BorderImage {
        height: 420px;
        width: 350px;
    }

    #OurStory #ImageSection #BorderImage #MainImage {
        height: 420px;
        width: 350px;
    }

    #OurStory #TextSection #BoldText {
        font-size: 28px;
    }
}

/* Responsive on pixels 768px */
@media screen and (max-width: 768px) {
    #OurStory {
        display: flex;
        flex-direction: column;
        /* height: 550px; */
    }

    #OurStory #ImageSection {
        align-self: center;
        margin-right: 80px;
        margin-left: 80px;
        /* margin-top: 120px; */
        margin-bottom: 30px;
    }

    #OurStory #ImageSection #BorderImage {
        height: 350px;
        width: 300px;
    }

    #OurStory #ImageSection #BorderImage #MainImage {
        height: 350px;
        width: 290px;
        margin: -20px -20px;
    }

    #OurStory #TextSection {
        margin-left: 0px;
        
    }

    #OurStory #TextSection #fAlign {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #OurStory #TextSection #BoldText {
        font-size: 24px;
    }

    #OurStory #TextSection #CentralText {
        font-size: 10px;
        line-height: 15px;
    }

    .TextStyling {
        font-size: 11px;
    }
}

/* Responsive on pixels 425px */
@media screen and (max-width: 425px) {
    #OurStory #ImageSection {
        align-self: center;
    }
    #OurStory #TextSection #BoldText {
        text-align: center;
    }

    #OurStory #TextSection {
        margin-left: 0px;
        
    }

    #OurStory #TextSection #hr {
        border: 2px solid #00A54D;
    }

    #OurStory #TextSection #CentralText {
        text-align: center;
        margin-bottom: 30px;
    }

    #OurStory #TextSection #Bullets {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 40px;
    }
}


/* Responsive on pixels 375px */
@media screen and (max-width: 375px) {
    #OurStory #TextSection #hr {
        border: 2px solid #00A54D;
    }
    #OurStory #TextSection {
        margin-left: 0px;
    }
}

/* Responsive on pixels 320px */</style>