<template>
    <div id="Background-div">
        <div id="Text">
            <div id="Bold-Text">
                <b>What We Offer</b>
            </div>

            <div id="hr"></div>

            <div id="Central-Text">
                At Evergreen Corporation, we offer a comprehensive range of construction solutions tailored to meet your every need. From residential to commercial projects, our skilled team delivers exceptional results with precision and efficiency. Explore our services and embark on your construction journey with confidence.
            </div>
        </div>
        <div id="DetailsGrid">
            <div class="Grids">
                <div class="GridImages1">
                    <!-- <img src="../assets/OfferImage1.png" alt="Not Found"> -->
                </div>
                <div class="Textures">
                    <div class="MainHeading"><b>We Understand Requirements</b></div>
                    <div class="DetailGrid">We specialize in understanding the distinct needs of the construction industry in Pakistan, particularly in Islamabad and beyond. Our tailored approach ensures that your projects are delivered with precision, reflecting local nuances and meeting regional standards. Trust us to bring your vision to life, with quality craftsmanship and attention to detail that's unmatched in the region.</div>
                    <div class="Button">
                        learn More <svg xmlns="http://www.w3.org/2000/svg" class="arrow-styling" height="1em"
                            viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                            <path
                                d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                        </svg>
                    </div>
                </div>
            </div>
            <div class="Grids">
                <div class="GridImages2">
                    <!-- <img src="../assets/OfferImage1.png" alt="Not Found"> -->
                </div>
                <div class="Textures">
                    <div class="MainHeading"><b>We Work Precisely</b></div>
                    <div class="DetailGrid">Our commitment to precision sets us apart as a leading construction partner in Pakistan. With a keen eye for detail and a dedication to excellence, we meticulously plan and execute every aspect of your project, ensuring unparalleled quality and satisfaction. Whether it's residential, commercial, or industrial construction, we approach each endeavor with precision and expertise, delivering results that exceed expectations. </div>
                    <div class="Button">
                        learn More <svg xmlns="http://www.w3.org/2000/svg" class="arrow-styling" height="1em"
                            viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                            <path
                                d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                        </svg>
                    </div>
                </div>
            </div>
            <div class="Grids">
                <div class="GridImages3">
                    <!-- <img src="../assets/OfferImage1.png" alt="Not Found"> -->
                </div>
                <div class="Textures">
                    <div class="MainHeading"><b>We Deliver Best Output</b></div>
                    <div class="DetailGrid">Our team prides itself on delivering the best output for your construction projects. With an unwavering commitment to excellence and a meticulous attention to detail, our dedicated team ensures that every aspect of your project is executed with precision and care. From initial planning to final execution, we go above and beyond to exceed expectations and provide results that not only meet but exceed industry standards. </div>
                    <div class="Button">
                        learn More <svg xmlns="http://www.w3.org/2000/svg" class="arrow-styling" height="1em"
                            viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                            <path
                                d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'WeOffer'
}
</script>
<style scoped>
* {
    margin: 0%;
    padding: 0%;
    font-family: 'Poppins', sans-serif;
}

#Background-div {
    background-color: #f5f5f5;
    /* height: 600px; */
    height: auto !important;
    padding: 70px 10%;
    width: 100%;
}

#Background-div #Text {
    padding-top: 45px;
    /* margin-left: 193px; */
    margin-bottom: 40px;
}

#Background-div #Text #Bold-Text {
    font-size: 35px;
}

#Background-div #Text #hr {
    border: 3px solid #00A54D;
    width: 60px;
    margin: 15px 0px;
}

#Background-div #Text #Central-Text {
    color: #5A5A5A;
    width: 40%;
    font-size: 13px;
}

#Background-div #DetailsGrid {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Grids {
    width: 40%;
    height: 500px;
    background-color: white;

}

.GridImages1 {
    height: 60%;
    width: 100%;
    background-image: url('../assets/image.png');
    background-repeat: no-repeat;
    background-size: cover;
}
.GridImages2 {
    height: 60%;
    width: 100%;
    background-image: url('../assets/Image2.png');
    background-repeat: no-repeat;
    background-size: cover;
}
.GridImages3 {
    height: 60%;
    width: 100%;
    background-image: url('../assets/Image3.png');
    background-repeat: no-repeat;
    background-size: cover;
}

/* .GridImages1{
        background-image: url('../assets/OfferImage1.png');
       
    } */
/* Pending Images Net Issue */
.Textures {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
}

.MainHeading {
    font-size: 15px;
    padding: 20px 20px;
}

.DetailGrid {
    font-size: 13px;
    color: #5A5A5A;
    padding-left: 20px;
}

.Button {
    display: flex;
    align-items: center;
    fill: #00A54D;
    color: #00A54D;
    font-size: 11px;
    padding: 20px 20px;
}

.Button:hover {
    text-decoration: underline black;
    fill: black;
    color: black;
}

.arrow-styling {
    margin-left: 10px;
}

@media screen and (max-width: 1024px) {
    #Background-div #DetailsGrid {
        flex-direction: column;
    }

    #Background-div #Text {
        width: 60%;
    }

    #Background-div #Text #Central-Text {
        width: 80%;
    }

    .Grids {
        width: 80%;
        margin: 10px 0px;
    }

    #Background-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 375px) {
    #Background-div #Text #Bold-Text {
        font-size: 30px;
    }
}
</style>