<template>
    <div id="OurStory">
        <div id="ImageSection">
            <div id="BorderImage">
                <div id="MainImage"></div>
            </div>
        </div>
        <div id="TextSection">

            <div id="BoldText">
                <b>Welcome to The EverGreen Corporation</b>
            </div>

            <div id="hr"></div>

            <div id="CentralText">
                Welcome to EverGreen Corporation, your trusted name in the construction industry, synonymous with quality and innovation. At EverGreen, we thrive on pushing the boundaries of conventional construction practices. Our unwavering dedication to innovation propels us forward, constantly seeking smarter and more efficient ways to build. We pride ourselves on staying at the forefront of advancements, embracing cutting-edge construction techniques, and incorporating sustainable practices into every project. From utilizing the latest technology to adopting eco-friendly materials, we are committed to creating structures that not only meet but exceed modern standards of excellence and sustainability. Join us in building the future, one innovative project at a time, with EverGreen Corporation by your side.
            </div>
        </div>
        
    </div>
</template>
<script>
export default {
    name:'WelcomeComp'
}
</script>
<style scoped>
    *{
        margin: 0%;
        padding: 0%;
        font-family: 'Poppins', sans-serif;
    }
    #OurStory{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 75px;
        margin-bottom: 50px;
        margin-left: 10%;
        margin-right: 10%;
        /* width: 100%; */
    }
    #OurStory #ImageSection{
        /* margin-left: 210px; */
    }
    #OurStory #ImageSection #BorderImage{
        height: 425px;
        width: 400px;
        border: 4px solid #00A54D;
    }
    #OurStory #ImageSection #BorderImage #MainImage{
        height: 425px;
        width: 400px;
        background-image: url('../assets/Welcome.png');
        background-position: center;
        background-size: cover;
        margin: -30px -30px;
    }
    #OurStory #TextSection {
        margin-left: 70px;
    }
    #OurStory #TextSection #BoldText{
        font-size: 30px;
    }
    #OurStory #TextSection #hr{
        border: 3px solid #00A54D;
        width: 60px;
        margin: 15px 0px;
    }
    #OurStory #TextSection #CentralText{
        line-height: 25px;
        font-size: 14px;
        margin-bottom: 10px;
        /* margin-right: 500px; */
        color: #5A5A5A;
    }
    #OurStory #TextSection #Bullets{
        display: flex;
        margin-bottom: 40px;
    }
    #OurStory #TextSection #Bullets #BulletSection2{
        margin-left: 50px;
    }
    .TextStyling{
        display: flex;
        align-items: center;
        fill: #00A54D;
        margin: 10px 0px;
        font-size: 14px;
        color: #5A5A5A;
    }
    .checkicon{
        margin-right: 10px;
    }


    /* Responsive on pixels 1440px */
@media screen and (max-width: 1440px) {
    #OurStory #TextSection{
        margin-bottom: 0px;
    }
    #OurStory #TextSection #CentralText{
        line-height: 20px;
    }
}

/* Responsive on pixels 1024px */
@media screen and (max-width: 1024px) {
    #OurStory #ImageSection #BorderImage{
        height: 420px;
        width: 350px;
    }
    #OurStory #ImageSection #BorderImage #MainImage{
        height: 420px;
        width: 350px;
    }
    #OurStory #TextSection #BoldText{
        font-size: 28px;
    }
}

/* Responsive on pixels 768px */
@media screen and (max-width: 768px) {
    #OurStory{
        display: flex;
        flex-direction: column;
        height: 550px;
    }
    #OurStory #ImageSection{
       align-self: center;
        margin-right: 80px;
        margin-left: 80px;
        margin-top: 120px;
        margin-bottom: 30px;
    }
    #OurStory #TextSection{
        margin-left: 80px;
        margin-bottom: 80px;

        /* margin-right: 260px; */
    }
    #OurStory #ImageSection #BorderImage{
        height: 250px;
        width: 190px;
    }
    #OurStory #ImageSection #BorderImage #MainImage{
        height: 250px;
        width: 190px;
        margin: -20px -20px;
    }
    #OurStory #TextSection #BoldText{
        font-size: 24px;
    }
    #OurStory #TextSection #CentralText{
        font-size: 10px;
        line-height: 15px;
    }
    .TextStyling{
        font-size: 11px;
    }
}

/* Responsive on pixels 425px */
@media screen and (max-width: 425px) {
    #OurStory #ImageSection{
       align-self: center;
    }
    #OurStory #TextSection{
        margin-right: 0px;
        margin-left: 0px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }


    #OurStory #TextSection #BoldText{
        text-align: center;
    }
    #OurStory #TextSection #hr{
        border: 2px solid #00A54D;
        margin-left: 170px;
        margin-right: 170px;
    }
    #OurStory #TextSection #CentralText{
        align-self: center;
        text-align: center;
        margin-left: 30px;
        margin-right: 30px;
        margin-bottom: 30px;
    }
}


/* Responsive on pixels 375px */
@media screen and (max-width: 375px) {
   #OurStory #TextSection #hr{
        border: 2px solid #00A54D;
        margin-left: 170px;
        margin-right: 170px;
    }
}

/* Responsive on pixels 320px */

    
   
 
</style>