<template>
    <div id="MoreDetails">

        <div id="Images">
            <img src="../assets/HomeMoreDetails.png" alt="img not found">
        </div>

        <div id="Texts">

            <div id="Bold-Text">
                <b>Construction Excellence with Evergreen Corporations</b>
            </div>
            <div id="hr"></div>

            <div id="Normal-Text">
                Evergreen Corporation stands at the forefront of the construction industry, epitomizing excellence in every project. As a pioneering force, we merge visionary construction solutions with an unwavering commitment to sustainability, setting new benchmarks for innovation and environmental stewardship. Our dedication to delivering superior results ensures a brighter and greener future for generations to come. Partner with Evergreen Corporation and elevate your construction endeavors to unparalleled heights of success, driven by our ethos of construction excellence and sustainability leadership.
            </div>

            <button id="Buttons">More Details <svg xmlns="http://www.w3.org/2000/svg" id="arrow-styling" height="1em"
                    viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                    <path
                        d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                </svg></button>
        </div>
    </div>
</template>
<script>
export default {
    name: 'HomeMoreDetails'
}
</script>
<style scoped>
* {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
}

#MoreDetails {
    display: flex;
    height: auto;
    /* background-color: #f1f1f1; */
    background-color: #fff;
    padding: 5% 4.411765%;
    /* 60px/1360 */
    justify-content: center;
    align-items: center;
}


#MoreDetails #Images,
#MoreDetails #Texts {
    margin: 20px;
}

#MoreDetails #Images img {
    height: 350px;
    width: 550px;
}

#MoreDetails #Texts {
    /* margin-top: -10px; */
    /* margin-left: 40px; */
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

#MoreDetails #Texts #Bold-Text {
    font-size: 50px;
    color: #222222;
    line-height: 3.7rem;
    /* text-align: center; */
}

#MoreDetails #Texts #hr {
    margin-top: 15px;
    margin-bottom: 10px;
    border: 2px solid #00A54D;
    width: 10%;
}

#MoreDetails #Texts #Normal-Text {
    color: #797978;
    font-size: 13px;
    /* margin: 30px 0px; */
}

#MoreDetails #Texts #Buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00A54D;
    fill: white;
    border: none;
    color: white;
    /* padding: 10px 25px; */
    height: 60px;
    width: 180px;
    text-align: center;
    font-size: 16px;
    width: 180px;
    margin-top: 20px;
}

#MoreDetails #Texts #Buttons #arrow-styling {
    margin-left: 5px;
}

#MoreDetails #Texts #Buttons:hover {
    cursor: pointer;
    border: 1px solid #00A54D;
    background-color: white;
    fill: black;
    color: black;
}

/* Responsive */

/* Responsive on pixels 1440 */
@media screen and (max-width: 1440px) {
    #MoreDetails #Texts #Bold-Text {
        font-size: 40px;
        line-height: 2.8rem;
    }

    #MoreDetails #Texts #hr {
        margin-top: 8px;
        margin-bottom: 8px;
        width: 12%;
    }

    #MoreDetails #Texts #Normal-Text {
        color: #797978;
        font-size: 13px;
        margin: 20px 0px;
    }

    #MoreDetails #Texts #Buttons {
        height: 47px;
        width: 160px;
        font-size: 13px;
    }
}

/* Responsive on pixels 1024 */
@media screen and (max-width:1024px) {
    #MoreDetails #Images img {
        height: auto;
        width: 450px;
    }

    #MoreDetails #Texts #Bold-Text {
        font-size: 26px;
        line-height: 1.5rem;
    }

    #MoreDetails #Texts #hr {
        margin-top: 10px;
        margin-bottom: 5px;
        width: 13%;
    }

    #MoreDetails #Texts #Normal-Text {
        color: #797978;
        font-size: 10px;
        margin: 13px 0px;
    }

    #MoreDetails #Texts #Buttons {
        height: 40px;
        width: 125px;
        font-size: 12px;
    }
}

/* Responsive on pixels 768 */
@media screen and (max-width:768px) {
    #MoreDetails {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* height: 600px; */
    }

    #MoreDetails #Images img {
        /* padding-top: 65px; */
        height: auto;
        width: 370px;
        /* margin-left: -40px; */
        align-self: center;
    }

    #MoreDetails #Texts #Bold-Text {
        text-align: center;
        /* margin-left: -40px; */
        /* padding-top: 10px; */
        font-size: 24px;
        line-height: 1.5rem;
    }

    #MoreDetails #Texts #hr {
        /* margin-left: 30%;
        margin-right: 30%; */
        align-self: center;
        margin-top: 10px;
        margin-bottom: 5px;
        width: 40%;
        margin-left: -40px;
    }

    #MoreDetails #Texts #Normal-Text {
        color: #797978;
        text-align: center;
        font-size: 12px;
        /* margin: 13px 0px; */
        /* margin-left: 70px; */
    }

    #MoreDetails #Texts #Buttons {
        align-self: center;
        height: 35px;
        width: 125px;
        font-size: 12px;
        /* margin-left: -40px; */
        margin-bottom: 25px;
    }
}

/* Responsive on pixels 425 */
@media screen and (max-width:425px) {
    #MoreDetails {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* height: 450px; */
    }

    #MoreDetails #Images img {
        height: 230px;
        width: 340px;
        /* margin-left: -40px; */
        align-self: center;
    }

    #MoreDetails #Texts #Bold-Text {
        text-align: center;
        /* margin-left: -40px; */
        font-size: 24px;
        line-height: 1.5rem;
    }

    #MoreDetails #Texts #hr {
        /* margin-left: 30%;
        margin-right: 30%; */
        align-self: center;
        margin-top: 10px;
        margin-bottom: 5px;
        width: 40%;
        /* margin-left: -40px; */
    }

    #MoreDetails #Texts #Normal-Text {
        color: #797978;
        text-align: center;
        font-size: 10px;
        margin: 13px 0px;
        /* margin-left: 70px; */
    }

    #MoreDetails #Texts #Buttons {
        align-self: center;
        height: 40px;
        width: 125px;
        font-size: 12px;
        /* margin-left: -40px; */
    }
}

/* Responsive on pixels 375 */
@media screen and (max-width:375px) {


    #MoreDetails #Images img {
        height: auto;
        width: 250px;
    }

    
}

/* Responsive on pixels 320 */</style>